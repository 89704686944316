import { useToggle } from 'react-use';
import { t } from '@src/utils/i18n';
import { Button, Modal } from '@tencent/tea-component';
import { useEffect } from 'react';

export const LoginUserChangedDialog = () => {
  const [visible, toggleVisible] = useToggle(false);

  useEffect(() => {
    function storageEventHandler(e: StorageEvent) {
      try {
        if (e.key === 'userid' && e.oldValue !== e.newValue) {
          const pathname = window.location.pathname;
          if (!['/login'].includes(pathname)) {
            toggleVisible(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    window.addEventListener('storage', storageEventHandler);
    return () => {
      window.removeEventListener('storage', storageEventHandler);
    };
  }, [toggleVisible]);

  const handlerClose = () => {
    window.location.reload();
  };

  return (
    <Modal visible={visible} caption={t('账户已切换')} onClose={handlerClose}>
      <Modal.Body>{t('您的账号已切换或退出，请刷新页面后重试')}</Modal.Body>
      <Modal.Footer>
        <Button type="primary" onClick={handlerClose}>
          {t('确定')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
