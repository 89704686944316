import axios from 'axios';
import queryString from 'query-string';

// 初始化 buffet-config-id 的 qs 并存储
export const initBuffetConfig = () => {
  const parsed = queryString.parse(location.search);
  console.log(parsed);
  const buffetConfigId = parsed['buffet-config-id'];
  if (buffetConfigId) {
    console.log(buffetConfigId);
    // 设置一个 5 小时后失效的 localStorage
    // localStorage.
  }

  axios
    .get('/', {
      responseType: 'text',
      params: {
        'buffet-config-id': buffetConfigId,
      },
    })
    .then(({ data }) => {
      // 获取最新 主页的 html 文件的 text 格式，匹配出它的 hash
      const hashRegexp = /<meta name="version" content="(.*?)"/;
      try {
        const remoteVersion = data.match(hashRegexp)[1];
        const versionMetaTag = document.querySelector('meta[name="version"]');
        const currentVersion = versionMetaTag?.getAttribute('content');
        console.log(remoteVersion, currentVersion);
        // TODO: 只有在正式环境发现 hash 不同，才刷新页面
      } catch (error) {
        console.log(error);
      }
    });
};
