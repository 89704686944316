import I18N from '@tencent/i18n';
import dict from '@i18n/dict.json';
import { getBaseLang } from '@src/global-context/getBaseLang';

export const i18n = new I18N(dict, getBaseLang() as any);

i18n.setLang = (v: 'zh' | 'en') => {
  localStorage.setItem('lang', v);
  i18n.language = v;
};

export const t = i18n.t;
export const Trans = i18n.Trans;
export const Slot = i18n.Slot;
export const lng = () => i18n.language;
