import { ReactNode, useEffect } from 'react';
import ConfigProvider2 from '@tencent/tpm-trtc-console/lib/ConfigProvider';
import {
  GlobalLangProvider,
  useGlobalLang,
} from './global-context/global-lang.ts';
import { ConfigProvider } from '@tencent/tea-component';
import { t } from '@src/utils/i18n';

interface Props {
  children: ReactNode;
}

const ConfigContainer = (props: Props) => {
  const [lang] = useGlobalLang();

  useEffect(() => {
    document.title = t('智能客服');
  }, [lang]);

  return (
    <ConfigProvider classPrefix={'trtc-tea'} locale={lang as 'zh' | 'en'}>
      <ConfigProvider2 classPrefix={'trtc-tea'}>
        {props.children}
      </ConfigProvider2>
    </ConfigProvider>
  );
};

export const MainContainer = (props: Props) => {
  return (
    <GlobalLangProvider>
      <ConfigContainer>{props.children}</ConfigContainer>
    </GlobalLangProvider>
  );
};
