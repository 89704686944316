import { RouterProvider } from 'react-router-dom';
import './App.css';
import './reset.css';
import { router } from './router';
import { useGlobalLang } from './global-context/global-lang';

function App() {
  const [lang] = useGlobalLang();
  return <RouterProvider router={router} key={lang} />;
}

export default App;
