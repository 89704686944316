// import { isIntlSite } from '@src/global-components/layout/const';

export const getBaseLang = () => {
  // if (!isIntlSite) return 'zh';

  const baseLang = localStorage.getItem('lang') || '';
  const langList = ['zh', 'en'];
  if (langList.includes(baseLang)) {
    return baseLang;
  }
  return 'zh';
};
